
<template>
  <div class="orderPage">
    <div class="head">
      <NavBar :showBack="true" title="ตั้งค่าโปรโมชั่น">
        <template #left-section>
          <div
            @click="$router.push('/settings/promotion/add')"
            class="btn addBtn"
          >
            + เพิ่มโปรโมชั่น
          </div>
        </template>
      </NavBar>
      <div :class="'collapse'">
        <Menu />
        <!-- <Filters
          v-if="showFilters"
          v-on:filters="updatefilters"
          :list="filtersList"
        /> -->
      </div>
    </div>
    <div :class="[{ hasFilters: showFilters }, 'orderPageContent']">
      <!-- <keep-alive> -->
      <!-- <div style="height: 30px"></div> -->
      <router-view />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Menu from "./Menu";
export default {
  components: { NavBar, Menu },
  data() {
    return {};
  },
  computed: {
    showFilters() {
      return this.$route.meta.filters === true;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.orderPage {
  background: #fff;
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.title {
  margin: 20px 0 0 20px;
  font-size: 24px;
  padding: 10px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.collapse {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 45px;
  transition: top 0.3s;
}
.collapse.hide {
  top: -95px;
}

.orderPageContent {
  height: 100%;
  // overflow-y: auto;
  // height: 90vh;
  &.hasFilters {
    padding: 110px 0 70px 0;
  }
}

.addBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 0;
  padding: 8px 0;
  margin-left: auto;
  width: 95px;
  font-size: 13px;
}
</style>
